// components/DashboardSummary.js
import React, { useEffect, useState } from "react";
import SummaryCard from "./SummaryCard";

const DashboardSummary = ({dashboardData}) => {
  const [fyp1Count, setFyp1Count] = useState(0);
  const [fyp2Count, setFyp2Count] = useState(0);

  useEffect(() => {
    if (dashboardData){
        if(dashboardData.fypCounts.length > 0){
            dashboardData.fypCounts.map((fypCount) => {
                if(fypCount.fyp_stage === 'FYP-I'){
                    setFyp1Count(fypCount.count);
                }
                else if (fypCount.fyp_stage === 'FYP-II'){
                    setFyp2Count(fypCount.count);
                }
            })
        }
    }
  }, [dashboardData]);


  const summaryData = [
    {
      imgSrc: "Assets/Face Twinkle.svg",
      title: "FYP - I Groups",
      count: `${fyp1Count}/3`,
      link: "/fyp-1/groups",
    },
    {
      imgSrc: "Assets/Users Icon.svg",
      title: "FYP - II Groups",
      count: `${fyp2Count}/3`,
      link: "FYP-II.html",
    },
    {
      imgSrc: "Assets/Book icon.svg",
      title: "Proposals To Review",
      count: "2 Assigned",
      link: "Proposals.html",
    },
  ];

  return (
    <div className="flex items-start justify-between mt-6 gap-16 w-full">
      {summaryData.map((item, index) => (
        <SummaryCard key={index} {...item} />
      ))}
    </div>
  );
};

export default DashboardSummary;