import React from "react";

const Modal = ({ isOpen, onClose, record }) => {
  if (!isOpen) return null;

  return (
    <div
      id="modal"
      className="fixed top-0 left-0 w-full h-full z-50 bg-black/30 flex justify-center items-center overflow-y-auto overflow-x-hidden"
    >
      <div className="relative w-[90%] left-[38%] max-w-4xl mx-auto rounded-lg">
        <div className="popup p-8 max-md:p-4 rounded shadow-md bg-gray-200">
          {/* Header */}
          <div className="flex justify-between mb-6">
            <h2 className="text-xl font-bold mb-4">Student Details:</h2>
            <i
              id="close"
              className="fas fa-times-circle fa-lg cursor-pointer"
              onClick={onClose}
            ></i>
          </div>

          {/* Group Details */}
          <div className="grid grid-cols-1 sm:grid-cols-6 gap-y-3 gap-x-4 mb-4">
            <div className="col-span-1 font-medium">Group Name</div>
            <div className="col-span-1 font-medium">Batch</div>
            <div className="col-span-1 font-medium">Program</div>
            <div className="col-span-1 font-medium">Area</div>
            <div className="col-span-1 font-medium">Category</div>
            <div className="col-span-1"></div>
            <div className="col-span-6 h-[1px] bg-black/30"></div>
            <div className="col-span-1">{record?.project.projectName}</div>
            <div className="col-span-1">{record?.batch || "N/A"}</div>
            <div className="col-span-1">{record?.program || "N/A"}</div>
            <div className="col-span-1">{record?.project.area}</div>
            <div className="col-span-1">{record?.project.category}</div>
            <div className="col-span-6 h-[1px] bg-black/30"></div>
          </div>

          {/* Student Details Table */}
          <table className="min-w-full border-collapse text-left mt-8 mb-6">
            <thead>
              <tr className="bg-white border border-gray-400">
                <th className="p-3">Student Id</th>
                <th className="p-3">Name</th>
              </tr>
            </thead>
            <tbody>
              {record.students.map((student, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
                >
                  <td className="p-3">{student.studentRegistrationNumber}</td>
                  <td className="p-3">{student.studentName}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Milestone Progress */}
          <div className={`flex items-center justify-center bg-white border-gray-400 border p-6 rounded-md`}>
            <div className="flex flex-col items-center text-xs gap-1 w-[48px]">
              <div className={`${record.fyp_group_status === 'Registered In FYP-I' ? 'bg-blue-600 text-white' : 'bg-blue-600 text-white'} w-12 h-12 rounded-full flex items-center justify-center`}>
                <i className={`${record.fyp_group_status === 'Registered In FYP-I' ? 'fas fa-circle' : 'fas fa-check'} `}></i>
              </div>
              <div>Proposal</div>
            </div>
            <div className="grow h-[2px] mt-[-15px] flex">
              <div className="grow bg-blue-600"></div>
              <div className={`${record.fyp_group_status === 'Registered In FYP-I' ? 'bg-gray-400' : 'bg-blue-600'} grow `}></div>
            </div>
            <div className="flex flex-col items-center text-xs gap-1 w-[48px]">
            <div className={`${record.fyp_group_status === 'Evaluated' ? 'border-2 border-blue-600 text-blue-600' : 'border-2 border-gray-400 bg-white text-blue-600'} w-12 h-12 rounded-full flex items-center justify-center`}>
                {/* <i className="fas fa-circle"></i> */}
              </div>
              <div className="text-center">Evaluation</div>
            </div>
            <div className="grow mt-[-15px] h-[2px] flex">
              <div className="grow bg-gray-400"></div>
              <div className="grow bg-gray-400"></div>
            </div>
            <div className="flex flex-col items-center text-xs gap-1 w-[48px]">
              <div className="w-12 h-12 border-2 border-gray-400 text-gray-400 rounded-full flex items-center justify-center"></div>
              <div className="text-center text-nowrap">FYP - 1 Report</div>
            </div>
          </div>

          {/* Button Container */}
          <div className="flex justify-end">
            <button className="bg-red-500 text-white px-4 py-2 rounded mt-4"
              onClick={onClose}
            >
              Done 
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
