import axios from "axios"

export const useSupervisor = () => {
    
    const getCoSupervisors = async () => {
        const token = localStorage.getItem('token')

        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}getCoSupervisorNames`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            return response.data.data
        }
        catch (error) {
            console.error(error)
        }
    }

    const getSupervisorDashboard = async () => {
        const token = localStorage.getItem('token')
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}supervisor/dashboardData`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            return response.data.data
        }
        catch (error) {
            console.error(error)
        }
    }
    return { getCoSupervisors, getSupervisorDashboard }
}