// components/ProposalsInfo.js
import React, { useEffect, useState } from "react";
const ProposalsInfo = ({dashboardData}) => {
    const [submitProposalCount, setSubmitProposalCount] = useState(0)
    const [minorProposalCount, setMinorProposalCount] = useState(0)
    const [majorProposalCount, setMajorProposalCount] = useState(0)
    const [acceptedProposalCount, setAcceptedProposalCount] = useState(0)

  useEffect(() => {      
    if (dashboardData){
        if(dashboardData.proposalCounts.length > 0){
            dashboardData.proposalCounts.map((proposalCount) => {
                if(proposalCount.proposalStatus === 'Submitted'){
                    setSubmitProposalCount(proposalCount.count);
                }
                else if (proposalCount.proposalStatus === 'Minor Revision'){
                    setMinorProposalCount(proposalCount.count);
                }
                else if (proposalCount.proposalStatus === 'Major Revision'){
                    setMajorProposalCount(proposalCount.count);
                }
                else if (proposalCount.proposalStatus === 'Accepted'){
                    setAcceptedProposalCount(proposalCount.count);
                }
            })
        }
    }
  }, [dashboardData]);

  return (
    <div className="border-2 border-black/30 rounded-sm h-[150px] flex flex-col">
      <div className="flex py-3 px-6 justify-between items-center border-b-2 border-black/30">
        <h3 className="text-base font-bold max-lg:text-sm">FYP - I Proposals Submitted</h3>
        <a className="text-[#1D242E] text-sm max-lg:text-xs" href="#">
          Go to Proposals »
        </a>
      </div>
      <div className="flex justify-between px-6 py-2 grow">
        <div className="text-center flex flex-col justify-center">
          <p className="text-xl max-lg:text-lg font-bold">{submitProposalCount}</p>
          <p className="text-sm max-lg:text-xs">Submitted</p>
        </div>
        <div className="text-center flex flex-col justify-center">
          <p className="text-xl max-lg:text-lg font-bold">{acceptedProposalCount}</p>
          <p className="text-sm max-lg:text-xs">Accepted</p>
        </div>
        <div className="text-center flex flex-col justify-center">
          <p className="text-xl max-lg:text-lg font-bold">{minorProposalCount}</p>
          <p className="text-sm max-lg:text-xs">Minor Revision</p>
        </div>
        <div className="text-center flex flex-col justify-center">
          <p className="text-xl max-lg:text-lg font-bold">{majorProposalCount}</p>
          <p className="text-sm max-lg:text-xs">Major Revision</p>
        </div>
      </div>
    </div>
  );
};

export default ProposalsInfo;