import React, { useEffect, useState } from "react";
import Header from "../../Global/Header/Header";
import DashboardSummary from "./DashboardSummary";
import ProposalsInfo from "./ProposalsInfo";
import ImportantDate from "./ImportantDate";
import { useSupervisor } from "../../../hooks/Supervisor/useSupervisor";
import Loader from '../../Global/Loader/Loader'


const Dashboard = () => {
  const { getSupervisorDashboard } = useSupervisor();
  const [dashboardData, setDashboardData] = useState();

  useEffect(() => {
    const getDashboard = async () => {
        const response = await getSupervisorDashboard();
        if (response){
            setDashboardData(response)
            console.log(response);
            
        }
    }
    getDashboard();
  }, []);

  return (
    <div className="flex-1 px-10 pb-10 pt-2">
      <Header />
      {!dashboardData && (
        <div>
          <Loader/>
        </div>
      )}
      <div className="px-2 text-left">
      <h1 className="text-[32px] font-bold">Welcome!</h1>
        <h2 className="text-[24px] font-bold mt-4">Dashboard</h2>
        <p className="text-gray-600">A quick data overview.</p>
      </div>
      <DashboardSummary dashboardData={dashboardData}/>
      <div className="grid grid-cols-2 gap-x-10 mt-8">
        <ProposalsInfo dashboardData={dashboardData} />
        <ImportantDate />
      </div>
    </div>
  );
};

export default Dashboard