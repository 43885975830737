import React from 'react'
// import Sidebar from "./Sidebar";
import MainContent from "./MainContent";

const AddGroups = () => {
  return (
    <div className="flex bg-gray-100">
      {/* <Sidebar /> */}
      <MainContent />
    </div>
  )
}

export default AddGroups